import styled from 'styled-components';
import {config} from 'react-awesome-styled-grid';

export const DropDownContainer = styled.div`
  width: 100%;
  z-index: 1;
  ul {
    li:last-child > div {
      border: none;
    }
  }
  margin-bottom: 0px;
  ${props => config(props).media['sm']`
    margin-bottom: 60px;
  `}
`;
export const DropDownHeader = styled.button`
  width: 100%;
  padding: 10px;
  display: flex;
  margin-top: 8px;
  border-radius: 6px;
  margin-bottom: 8px;
  align-items: center;
  background: transparent;
  justify-content: space-between;
  border: 1px solid ${({theme}) => theme.colors.secondary2};
  img {
    height: 6px;
    width: 10px;
    transform: ${props => (props.isOpen ? 'rotate(180deg)' : 'rotate(0deg)')};
  }
  cursor: pointer;
  :disabled {
    pointer-events: none;
    opacity: 0.7;
    cursor: default;
  }
`;
export const DropDownHeaderItemIcon = styled.div``;
export const DropDownHeaderItem = styled.p``;
export const DropDownList = styled.ul`
  max-height: 345px;
  overflow-y: auto;
  width: 100%;
  margin: 0;
  border-top: 0;
  background: white;
  list-style: none;
  margin-bottom: 44px;
`;

export const DropDownListItem = styled.li`
  padding: 5px;
  background: ${props => (props.ishighlighted ? '#D7D9DC' : '')};
  border-radius: 8px;
  padding-left: 20px;
  padding-right: 20px;
  cursor: pointer;

  .dropdownlistwrapper {
    display: flex;
    align-items: center;
    border-bottom: 1px solid ${({theme}) => theme.colors.secondary2};
    height: 80px;
    flex-direction: row;
    > div {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: white;
      height: 50px;
      width: 50px;
      border-radius: 10px;
      border: 1px solid ${({theme}) => theme.colors.secondary2};
      img {
        width: 100%;
      }
    }
    div :last-child {
      flex-direction: column;
      align-items: flex-start;
      background: transparent;
      border: none;
      width: 100%;
      margin-left: 12px;
    }
  }

  .dropdown-list-wrapper {
    display: flex;
    align-items: center;
    border-bottom: 1px solid ${({theme}) => theme.colors.secondary2};
    height: 80px;
    flex-direction: row;
    .dropdown-list-image {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: white;
      height: 50px;
      width: 50px;
      border-radius: 10px;
      border: 1px solid ${({theme}) => theme.colors.secondary2};
      img {
        width: 100%;
      }
      margin-right: 20px;
    }
    .dropdown-list-info {
      display: flex;
      /* align-items: center; */
      justify-content: space-between;
      width: 200px;
      ${props => config(props).media['md']`
        width: 350px;
      `}
      ${props => config(props).media['lg']`
        width: 450px;
      `}
    }
    .dropdown-list-stock {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
    }
  }
  h2 {
    width: 100%;
    color: ${({theme}) => theme.colors.secondary};
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
  }
`;
