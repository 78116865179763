import React from 'react';
import PropTypes from 'prop-types';
import {useSelect} from 'downshift';

import useImgixImage from '../../../hooks/useImgixImage';
import {DropDownContainer, DropDownHeader, DropDownList} from './styles';

const DropDown = ({
  items,
  selectItem,
  placeholder,
  isLoading,
  itemComponent,
  onClick,
  disabled,
  stateDropdown,
}) => {
  const ArrowDown = useImgixImage('/images/icon/arrows/arrow-down.png');
  const {
    isOpen,
    selectedItem,
    getToggleButtonProps,
    getMenuProps,
    highlightedIndex,
    getItemProps,
  } = useSelect({items});
  return (
    <DropDownContainer>
      <DropDownHeader isOpen={isOpen} {...getToggleButtonProps({disabled})}>
        {stateDropdown && selectItem
          ? selectedItem
            ? selectItem(selectedItem)
            : placeholder
          : placeholder}
        <img src={ArrowDown} />
      </DropDownHeader>
      <DropDownList {...getMenuProps()} onClick={onClick}>
        {isOpen &&
          !isLoading &&
          items.map((item, index) =>
            itemComponent(item, index, highlightedIndex, getItemProps)
          )}
      </DropDownList>
      <div tabIndex="-1" />
    </DropDownContainer>
  );
};

export default DropDown;

DropDown.defaultProps = {
  selectItem: () => {
    console.warn('You must pass a selectItem function');
  },
};

DropDown.propTypes = {
  isLoading: PropTypes.bool,
  items: PropTypes.array,
  itemComponent: PropTypes.func,
  stateDropdown: PropTypes.bool,
  ishighlighted: PropTypes.bool,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  selectItem: PropTypes.func,
  onClick: PropTypes.func,
};
