import Modal from 'react-modal';
import PropTypes from 'prop-types';
import {Link, navigate} from 'gatsby';
import React, {useState, useEffect} from 'react';
import {Row, Col, Hidden, Visible} from 'react-awesome-styled-grid';

import Menu from '../Menu';
import ModalLogin from './modalLogin';
import DropDown from '../core/Dropdown';
import ButtonIcon from '../core/ButtonIcon';
import InputSearch from '../core/InputSearch';
import {useGlobalContext} from '../../context';
import useImgixImage from '../../hooks/useImgixImage';
import DropDownItem from '../core/Dropdown/DropDownItem';
import {isLoggedIn, logout} from '../../services/authentication';
import {Navigation, LogoWrap, Toggle, Hamburger} from './styles';
import {clearCurrentOrder, getOrder} from '../../services/temporalOrder';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    width: 'auto',
    color: 'red',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '18px',
  },
  overlay: {
    zIndex: '',
  },
};

const Logo = ({className}) => {
  const logo = 'https://e-makers-production.s3.amazonaws.com/image+(1).png';
  const newLogo = 'https://e-makers-production.s3.amazonaws.com/image.png';
  return (
    <LogoWrap as={Link} to="/" className={className}>
      <div>
        <img src={logo} alt="logo" />
        <img src={newLogo} alt="newLogo" />
      </div>
    </LogoWrap>
  );
};

const NavbarLinks = () => {
  const userblack = useImgixImage('/images/user-black.png');
  const cartblack = useImgixImage('/images/cart-black.png');
  const heartblack = useImgixImage('/images/heart-black.png');
  const imgAccount = useImgixImage('/images/account-icn.png');
  const imgLogOut = useImgixImage('/images/logOut.png');

  const {
    modalIsOpen,
    setModalIsOpen,
    userLocal,
    refreshUser,
    currentOrder,
    setUserLocal,
    getNumberCurrentOrder,
  } = useGlobalContext();

  const {total_quantity, state} = currentOrder || '';

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const userAccount = [
    {
      images: `${imgAccount}`,
      title: 'Account',
      funct: () => navigate('/account?setting=personal-info'),
    },
    {
      images: `${imgLogOut}`,
      title: 'Log out',
      funct: () => handleLogOut(),
    },
  ];

  const handleLogOut = () => {
    logout(() => {
      navigate('/');
      refreshUser();
      clearCurrentOrder();
      setUserLocal(null);
    });
  };

  useEffect(() => {
    if (getOrder()) {
      total_quantity;
      getNumberCurrentOrder();
    }
  }, [currentOrder]);

  const divButton = key => {
    return (
      <button
        style={{
          border: 'none',
          background: 'none',
          'font-weight': '500',
          'margin-left': '6px',
        }}
      >
        {key}
      </button>
    );
  };

  return (
    <>
      <div className="style-button">
        {userLocal ? (
          <div className="login-account-wrap">
            <DropDown
              items={userAccount}
              placeholder={`Hello, ${userLocal.first_name}`}
              selectItem={false}
              itemComponent={(item, index, highlightedIndex, getItemProps) => (
                <DropDownItem
                  index={index}
                  onClick={item.funct}
                  miniature={<img src={item.images} />}
                  title={divButton(item.title)}
                  itemProps={getItemProps({item, index})}
                  highlightedIndex={highlightedIndex}
                />
              )}
            />
          </div>
        ) : (
          <Visible sm md lg xl>
            <button
              className={'button-user'}
              icon={userblack}
              onClick={openModal}
            >
              <img src={userblack} />
            </button>
          </Visible>
        )}
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Example Modal"
        >
          <ModalLogin />
        </Modal>
        {isLoggedIn() && (
          <Link to={'/favorite-page'} className="link-button">
            <ButtonIcon icon={heartblack} borderColor={'#d7d9dc'}></ButtonIcon>
          </Link>
        )}

        {!process.env['GATSBY_ORDER_ACTIVATION'] === true ? (
          ''
        ) : (
          <Visible sm md lg xl>
            <Link to={'/cart'} className="link-button-items">
              <ButtonIcon as={Link} to="/cart" icon={cartblack}></ButtonIcon>
              {isLoggedIn() &&
                getOrder() &&
                currentOrder &&
                state !== 'pending' &&
                total_quantity >= 1 && (
                  <div className="link-button-number-items">
                    <ButtonIcon
                      to="/cart"
                      number={
                        total_quantity >= 1 && total_quantity <= 99
                          ? total_quantity
                          : '99+'
                      }
                    ></ButtonIcon>
                  </div>
                )}
            </Link>
          </Visible>
        )}
      </div>
    </>
  );
};

const Navbar = () => {
  const [navbarOpen, setNavbarOpen] = useState(false);

  return (
    <Navigation>
      <Row>
        <Col xs={4} sm={7} md={2.5} lg={2} justify="flex-start">
          <Logo />
        </Col>
        <Col xs={4} sm={8} md={3} lg={8} align="center">
          <InputSearch />
        </Col>
        <Hidden xs sm>
          <Col sm={0} md={2.5} lg={2} align="end">
            <NavbarLinks />
          </Col>
        </Hidden>

        <Toggle
          navbarOpen={navbarOpen}
          onClick={() => setNavbarOpen(!navbarOpen)}
        >
          {navbarOpen ? <Hamburger open /> : <Hamburger />}
        </Toggle>
      </Row>
      <Row>
        <Col md={8} align="center">
          <Menu isOpen={navbarOpen} setNavbarOpen={setNavbarOpen} />
        </Col>
      </Row>
    </Navigation>
  );
};

export default Navbar;

Logo.propTypes = {
  className: PropTypes.node,
};
