export const getOptionValuesByType = (product, optionType) => {
  if (!product) return [];
  const optionValues = product.variants
    .map(variant => {
      return variant.option_values.find(
        optionValue =>
          optionValue.option_type_name.toLowerCase() ===
          optionType.toLowerCase()
      );
    })
    .filter(optionValue => optionValue);

  return optionValues;
};

export const findVariantByOptionValue = (variants, optionValue) => {
  const variant = variants.find(
    variant => !!variant.option_values.find(({id}) => id === optionValue.id)
  );

  return variant;
};

export const generateImageUrl = url => {
  const PRODUCT_URL = process.env['GATSBY_PRODUCT_URL'];
  if (!PRODUCT_URL) return url;

  return `${PRODUCT_URL}${url}`;
};

export const filterVariantsByOptionType = (itemSelected, optionType) => {
  const variant = itemSelected.option_values;
  if (!variant) return [];
  return variant.filter(item => {
    if (item.option_type_name !== optionType) {
      return false;
    }
    return item;
  });
};

export const getOptionValueByOptionType = (variant, toSearch, label) => {
  const value = variant.find(
    ({option_type_name}) => option_type_name?.toLowerCase() === toSearch
  );
  return value ? value[label] : '';
};

export const handleDateByListOrder = data => {
  const dateArray = data?.split('T')[0];
  return dateArray ? dateArray : '';
};

export const findMasterIdByVariantId = (products, variant_id) => {
  const value = products.find(product =>
    product.variants.find(variant => variant.id === variant_id)
  );
  const valueId = value?.id;
  return valueId ? valueId : '';
};

export const urlAmazonS3 = key => {
  return `https://guarapo-makers-staging.s3.amazonaws.com${key}`;
};
